import * as React from 'react';

import {
  Colors,
} from '@bluecurrent/web-component-lib';

import YouTube from './YouTube';

export default function Player(props) {
  const ref = React.useRef(null);

  const [height, setHeight] = React.useState(500);

  const getHeight = () => {
    if (ref.current.clientHeight !== null) setHeight(ref.current.clientHeight);
  };

  React.useEffect(() => {
    getHeight();
  }, [height]);

  React.useEffect(() => {
    window.addEventListener('resize', getHeight);

    return () => window.removeEventListener('resize', getHeight);
  }, []);

  const renderPlayer = () => {
    switch (props.player) {
      case 'youtube':
        return (
          <YouTube
            id={props.id}
            height={height}
            isVideoActive={props.isVideoActive}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        height: props.height,
        minHeight: 500,
        maxHeight: 560,
        backgroundColor: Colors.LIGHT_GREY,
        borderRadius: 10,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {renderPlayer()}
    </div>
  );
}
