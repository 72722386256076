import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Colors,

  Icon,
  Confetti,
  Loader,
} from '@bluecurrent/web-component-lib';

export default function Thumbnail(props) {
  const [isLoading, setLoading] = React.useState(false);

  return (
    <motion.button
      type="button"
      className="DisableSelection Hover"
      onClick={() => {
        setLoading(true);
        props.setVideoActive();
      }}
      style={{
        width: '100%',
        height: props.height,
        minHeight: 500,
        maxHeight: 560,
        backgroundColor: Colors.LIGHT_GREY,
        borderRadius: 10,
        border: 'solid 0px transparent',
        backgroundImage: props.src !== undefined && `url("${props.src}?q=100")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 1,
        overflow: 'hidden',
      }}
      initial={{
        opacity: 1,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        delay: 0.8,
        duration: 0.5,
      }}
    >
      <div
        className="Blur"
        style={{
          height: 80,
          width: 80,
          backgroundColor: 'rgba(55, 66, 74, 0.2)',
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
          position: 'relative',
        }}
      >
        {
          isLoading ? (
            <Loader
              color={Colors.WHITE}
            />
          ) : (
            <Icon
              name="Play"
              height={38}
              width={38}
              color={Colors.WHITE}
              iconSet="FA"
            />
          )
        }
      </div>
      <Confetti
        type="DotsPath"
        height={1000}
        width={1000}
        position="absolute"
        transform="rotate(100deg)"
        right={-500}
        top={-100}
        opacity={0.8}
      />
    </motion.button>
  );
}
