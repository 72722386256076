const getVideoThumbnailImage = (
  id,
  index,
  objectIndex,
) => `
  *[_id == "${id}"] {
    layout[${index}] {
      Components[${objectIndex}] {
        video {
          thumbnail {
            "src": asset->url
          }
        }
      }
    }
  }
`;

export default getVideoThumbnailImage;
