import * as React from 'react';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';

import {
  Lang,

  Title,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../config/Setup.config';

import useSanityData from '../../../hooks/useSanityData';

import handleHeadingPrioritization from '../../../functions/handleHeadingPrioritization';

import getVideoThumbnailImage from '../../../services/Sanity/query/getImage/getVideoThumbnailImage';

import Thumbnail from './Thumbnail';
import Player from './Player';

export default function Video(props) {
  const { i18n } = Lang.useTranslation();
  const thumbnail = useSanityData(
    getVideoThumbnailImage(
      props.params.id,
      props.params.index,
      props.params.objectIndex,
    ),
  );

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;
  const src = thumbnail[props.params.layoutIndex]?.layout?.Components?.video?.thumbnail?.src;
  const hasBlueprint = props.params.blueprint !== 'single';
  const height = hasBlueprint ? '100%' : '65vh';

  const [isVideoActive, setVideoActive] = React.useState(false);

  const videoIdHandler = () => {
    switch (props.data.video_type) {
      case 'youtube':
        return props.data.youtube_id;
      default:
        return undefined;
    }
  };

  return (
    <motion.div
      style={{
        width: '100%',
        paddingTop: 30,
        paddingBottom: 50,
      }}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 100,
      }}
      viewport={{
        once: true,
      }}
    >
      {
        props.data.title && (
          <div
            style={{
              marginBottom: 30,
            }}
          >
            <Title
              textAlign="center"
              fontFamily="Caveat"
              fontWeight={300}
              fontSize="2.8em"
              heading={handleHeadingPrioritization(props.params.index)}
            >
              {props.data.title[lang]}
            </Title>
          </div>
        )
      }
      <div
        style={{
          width: '100%',
          height,
          minHeight: 500,
          maxHeight: 560,
          position: 'relative',
          zIndex: 1,
        }}
      >
        <AnimatePresence>
          {
            !isVideoActive && (
              <Thumbnail
                setVideoActive={() => setVideoActive(true)}
                src={src}
                height={height}
              />
            )
          }
        </AnimatePresence>
        {
          isVideoActive && (
            <Player
              player={props.data.video_type}
              id={videoIdHandler()}
              isVideoActive={isVideoActive}
            />
          )
        }
      </div>
    </motion.div>
  );
}
