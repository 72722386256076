import * as React from 'react';
import YouTube from 'react-youtube';

export default function YouTubePlayer(props) {
  const options = {
    height: props.height ?? 500,
    width: '100%',
    playerVars: {
      autoplay: props.isVideoActive ? 1 : 0,
    },
  };

  const onReady = (event) => {
    event.target.playVideo();
    event.target.setPlaybackQuality('hd720');
  };

  const onStateChange = (event) => {
    event.target.setPlaybackQuality('hd720');
  };

  return (
    <YouTube
      videoId={props.id}
      opts={options}
      onReady={onReady}
      onStateChange={onStateChange}
    />
  );
}
